/*@font-face {*/
/*  font-family: 'CustomFont';*/
/*  src: url("./assets/fonts/SVN-Gilroy Black.woff2"),*/
/*  url("./assets/fonts/SVN-Gilroy Black Italic.woff2"),*/
/*  url("./assets/fonts/SVN-Gilroy Italic.woff2"),*/
/*  ;*/
/*}*/
@font-face {
  font-family: 'Gilroy-Light';
  src: url("./assets/fonts/SVN-Gilroy Thin.woff2"),
  url("./assets/fonts/SVN-Gilroy Thin Italic.woff2"),
  url("./assets/fonts/SVN-Gilroy Light.woff2"),
  url("./assets/fonts/SVN-Gilroy Light Italic.woff2"),
  url("./assets/fonts/SVN-Gilroy Xlight.woff2"),
  url("./assets/fonts/SVN-Gilroy Xlight Italic.woff2");
}
@font-face {
  font-family: "Gilroy-Medium";
  src: url("./assets/fonts/SVN-Gilroy Medium.woff2"),
  url("./assets/fonts/SVN-Gilroy Regular.woff2");
}
@font-face {
  font-family: 'Gilroy-SemiBold';
  src: url("./assets/fonts/SVN-Gilroy SemiBold.woff2"),
  url("./assets/fonts/SVN-Gilroy SemiBold Italic.woff2");
}
@font-face {
  font-family: 'Gilroy-Bold';
  src: url("./assets/fonts/SVN-Gilroy Bold.woff2"),
  url("./assets/fonts/SVN-Gilroy Bold Italic.woff2"),
  url("./assets/fonts/SVN-Gilroy Heavy.woff2"),
  url("./assets/fonts/SVN-Gilroy Heavy Italic.woff2"),
  url("./assets/fonts/SVN-Gilroy XBold.woff2"),
  url("./assets/fonts/SVN-Gilroy XBold Italic.woff2");
}
body {
  margin: 0;
  font-family: 'Gilroy-Medium', sans-serif;
  font-weight: normal !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
a{
  color: inherit;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
