.table-striped>tbody>tr:nth-of-type(odd){
    --bs-table-accent-bg: #E0E0E0 !important;
}
.item-note{
    width: 36px;
    height: 12px;
    border-radius: 4px;
    background-color: red;
}
.table-schedule td{
    text-align: center;
}
.table-schedule th{
    text-align: center;
}
.select-roll{
    background: transparent;
    border: 0;
    width: 20px;
    outline: none;
}