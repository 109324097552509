.heading{
    font-size: 18px;
    font-weight: 600;
}
.input-textarea{
    height: 100px;
}
.select-container{
    padding: 7.5px 12px;
    outline: none;
}