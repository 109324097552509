.overlay-modal{
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.4);
    z-index: 1;
    transition: all 1s;
}
.sidebar-content{
    position: relative;
    z-index: 1000;
    background-color: #f3f5f7;
    border-radius: 4px;
    transition: 1s all;
}

@media only screen and (min-width: 994px) {
    .overlay-modal{
        display: none;
    }
    .vertical-menu{
        width: 260px !important;
    }
    .sidebar-content{
        transform: translateX(0) !important;
    }
}