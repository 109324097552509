.table-info{
    color: #8687A7
}
.dropdown-part{
    width: 20px;
    height: 20px;
    display: flex !important;
    justify-content: center;
    align-items: center;
    background-color: transparent !important;
}
.option-icon{
    color: black;
}
.pagination{
    justify-content: center;
}