#page-topbar{
    box-shadow: 0 0 8px #43494e;
}
.toggle{
    position: relative;
    width: 25px;
    height: 25px;
}
.toggle .common{
    position: absolute;
    height: 2px;
    width: 18px;
    background: #cccccc;
    border-radius: 50px;
    transition: 0.3s ease;
}
.toggle .top-line{
    top: 30%;
    left: 50%;
    transform: translate(-50%,-50%);
}
.toggle .middle-line{
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}
.toggle .bottom-line{
    top: 70%;
    left: 50%;
    transform: translate(-50%,-50%);
}
.sidebar-enable .toggle .top-line{
    top: 14px;
    left: 2px;
    width: 18px;
    transform: rotate(45deg);
}
.sidebar-enable .toggle .middle-line{
    top: 14px;
    left: 2px;
    width: 18px;
    transform: rotate(-45deg);
}
.sidebar-enable .toggle .bottom-line{
    opacity: 0;
    transform: translateX(20px);
}