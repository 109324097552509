.avatar{
    width: 80px;
    height: 80px;
    object-fit: cover;
}
.input-textarea{
    min-height: 65px !important;
}
.sub-label{
    font-size: 14px;
}