.card-container{
    border-radius: 6px;
    padding: 20px;
    background-color: #FFFFFF;
    box-shadow: 0 0 8px #ccc;
    position: relative;
    z-index: 1;
}
.heading{
    font-weight: 500;
}
.filter{
    font-size: 12px;
    color: #8687A7;
}
.input{
    width: 120px;
    height: 100%;
    border: 1px solid #ccc;
    outline: none;
    border-radius: 3px;
}
.select-wrapper{
    border: 1px solid #ccc;
    border-radius: 3px;
    padding: 0 8px 0 0;
}