.page-content{
    color: #343A40;
}
.heading{
    font-size: 14px;
    font-weight: 500;
}
.label{
    font-size: 14px;
    color: #9095AD;
    margin-top: 10px;
    font-weight: 600;
}
.info{
    font-size: 12px;
    color: #343A40;
}
.card-container{
    border-radius: 6px;
    padding: 20px;
    background-color: #FFFFFF;
    box-shadow: 0 0 8px #ccc;
    position: relative;
    z-index: 1;
}
.input-edit-info{
    height: 28px;
    border: 1px solid #cccccc;
    outline: none;
    border-radius: 4px;
    padding: 8px 10px;
}
.disabled{
    background-color: #EEEEEE;
}
.input-textarea{
    min-height: 65px !important;
}
.btn-test{
    width: 120px;
    height: 28px;
    display: flex !important;
    margin: 0 10px;
    align-items: center;
    justify-content: center;
}
.asterisk::after{
    content:" *";
    color: red;
    font-size: 16px;
}
.message-err{
    color: #FF715B;
    font-size: 13px;
}