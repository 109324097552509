.avatar{
    width: 80px;
    height: 80px;
    border: 1px solid #8687A7;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.card-userId{
    width: 200px;
    height: 125px;
    border: 1px solid #8687A7;
    border-radius: 4px;
    margin-top: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.card span{
    font-size: 11px;
    display: block;
    height: 15px;
}
.avatar span{
    font-size: 11px;
    display: block;
    height: 15px;
}